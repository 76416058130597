body {
  font-family: "Roboto", sans-serif;
}
@font-face {
  font-family: "Roboto";
  src: local("Roboto"), local("Roboto-Regular"),
    url("/tr/assets/fonts/Roboto-Regular.woff2") format("woff2"),
    url("/tr/assets/fonts/Roboto-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
.ipo-title th{
  text-align: center;
  color: #ffffff;
  background: linear-gradient(135deg, #c78b2b, #af6e24) !important;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  z-index: 100;
}
.subnav {
  font-size: 14px;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
  padding-left: 2px;
  padding-right: 2px;
}

.hr {
  margin-top: 15px;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  height: 5px;
}
.grid-striped .row:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
}
.archive-style {
  margin-top: 7px;
  margin-bottom: 10px;
  padding-left: 7px;
  height: 70px;
}
.archive-title-style {
  padding: 7px;
  font-weight: bold;
}
.visible-title {
  color: white;
  font-size: xx-small;
}
.adsHeader {
  width: 1036px;
  height: 200px;
}
.adsEdge {
  width: 150px;
  height: 200px;
  position: fixed;
}
.adsEdgeL {
  width: 150px;
  height: 200px;
  position: fixed;
  left: 1190px;
}
.container-mr {
  max-width: 800px;
  min-width: 640px;
  margin: 0 auto;
}
.container-mr .column {
  width: 50%;
  padding: 0 25px;
  box-sizing: border-box;
  float: left;
}
@media (max-width: 390px) {
  .column-mr {
    width: 400px;
    display: flex;
    align-items: center;
  }
}

@media only screen and(min-width:400px) and (max-width: 760px) {
  .column-mr {
    width: 215px;
    display: flex;
    align-items: center;
  }
}

@media only screen and(min-width:760px) and (max-width: 992px) {
  .column-mr {
    width: 215px;
    display: flex;
    align-items: center;
  }
}
@media (min-width: 992px) {
  .column-mr {
    width: 340px;
    display: flex;
    align-items: center;
  }
}
.container-mr .column .demo-title {
  margin: 0 0 15px;
  color: #666666;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}

@media (min-width: 360px) {
  .post-module {
    position: relative;
    z-index: 1;
    background: #ffffff;
    width: 89%;
    height: 300px;
    align-items: center;
    margin: 5px 20px;
    -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
  }
}

@media (max-width: 390px) {
  .post-module {
    position: relative;
    z-index: 1;
    background: #ffffff;
    width: 95%;
    height: 300px;
    align-items: center;
    margin: 7px;
    -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
  }
}

@media (min-width: 760px) {
  .post-module {
    position: relative;
    z-index: 1;
    display: block;
    background: #ffffff;
    width: 85%;
    height: 300px;
    align-items: center;
    margin: 25px;
    -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
  }
}

@media only screen and(min-width:760px) and (max-width: 992px) {
  .post-module {
    position: relative;
    z-index: 1;
    display: block;
    background: #ffffff;
    width: 245px;
    height: 280px;
    -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
  }
}
@media (min-width: 992px) {
  .post-module {
    position: relative;
    z-index: 1;
    display: block;
    background: #ffffff;
    width: 230px;
    height: 280px;
    padding: 5px;
    -webkit-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
    -webkit-transition: all 0.3s linear 0s;
    -moz-transition: all 0.3s linear 0s;
    -ms-transition: all 0.3s linear 0s;
    -o-transition: all 0.3s linear 0s;
    transition: all 0.3s linear 0s;
  }
}

.post-module .thumbnail {
  background: #000000;
  height: 275px;
  overflow: hidden;
}
.post-module .thumbnail .date {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
  background: #f43030bd;
  width: 55px;
  height: 55px;
  padding: 12.5px 0;
  border-radius: 100%;
  color: #ffffff;
  font-weight: 700;
  text-align: center;
  box-sizing: border-box;
}

.post-module .thumbnail .date .day {
  font-size: 18px;
}
.post-module .thumbnail .date .month {
  font-size: 12px;
  text-transform: uppercase;
}
.post-module .post-content {
  position: absolute;
  bottom: 0;
  background: #ffffff;
  width: 100%;
  padding: 6px;
  height: 130px;
  box-sizing: border-box;
  transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
}

.post-module .post-content .category {
  position: absolute;
  top: -34px;
  left: 0;
  background: #1a5571;
  padding: 10px 15px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}
.post-module .post-content .title {
  margin: 0;
  padding: 0 0 10px;
  color: #333333;
  font-size: 26px;
  font-weight: 700;
}
.post-module .post-content .sub_title {
  margin: 0;
  padding: 0 0 20px;
  color: #e74c3c;
  font-size: 20px;
  font-weight: 400;
}
.post-module .post-content .description {
  display: none;
  color: #666666;
  font-size: 14px;
  line-height: 1.8em;
}
.post-module .post-content .post-meta {
  margin: 30px 0 0;
  color: #999999;
}
.post-module .post-content .post-meta .timestamp {
  margin: 0 16px 0 0;
}

.two h3 {
  text-transform: capitalize;
}
.two h3:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: "";
  background-color: #c50000;
}

.two h3 span {
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 4px;
  line-height: 3em;
  padding-left: 0.25em;
  color: rgba(0, 0, 0, 0.4);
  padding-bottom: 10px;
}
.alt-two h3 {
  text-align: center;
}
.alt-two h3:before {
  left: 50%;
  margin-left: -30px;
}

.h3-mr {
  position: relative;
  padding: 0;
  margin: 0;
  font-weight: 300;
  font-size: 2rem;
  color: #080808;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.h3-mr span {
  display: block;
  font-size: 0.5em;
  line-height: 1.3;
}
.h3-mr em {
  font-style: normal;
  font-weight: 600;
}
.flex-container {
  display: flex;
  flex-direction: row;
}
@media (max-width: 760px) {
  .flex-container {
    flex-direction: column;
  }
}
.overlay-mr {
  border-radius: 1rem;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.24);
  z-index: 1;
}
.container-mrr {
  align-items: center;
  background: #f3f3f3;
}

.card-mr {
  border-radius: 1rem;
  background: white;
  box-shadow: 4px 4px 15px rgba(#000, 0.15);
  position: relative;
  color: #434343;
}

.card-mr .card__container {
  padding: 2rem;
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 1rem;
  position: relative;
}

.card-mr .card__header {
  margin-bottom: 1rem;
}

.card-mr::before {
  position: absolute;
  top: 2rem;
  right: -0.5rem;
  content: "";
  background: #283593;
  height: 28px;
  width: 28px;
  transform: rotate(45deg);
}

#rcorners2 {
  border-radius: 1rem;
  border: 2px solid #73ad21;
}

.card-mr::after {
  position: absolute;
  content: attr(data-label);
  top: 11px;
  right: -14px;
  padding: 0.5rem;
  width: 10rem;
  background: #ecaf08;
  color: white;
  text-align: center;
  box-shadow: 4px 4px 15px rgba(26, 35, 126, 0.2);
  z-index: 1;
}
.fa-2x {
  font-size: 2.5em;
}
.fa-ellipsis {
  color: orange;
  font-size: 35px;
}
.draft-ipo-list-home {
  position: relative;
  min-height: 150px;
}
.list-marker::marker {
  content: "⭐";
}
.avatar-image {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  flex-shrink: 0;
  object-fit: cover;
}

.px-mr-2 {
  padding-right: 0.5rem !important;
  padding-left: 1.5rem !important;
}
.mx-mr-5 {
  margin-left: 11.1rem !important;
}
.list-ic a {
  color: white;
  text-decoration: none;
}
.list-ic li {
  position: relative;
}
.list-ic li span {
  display: inline-block;
  font-weight: 800;
  width: 2em;
  height: 2em;
  text-align: center;
  line-height: 2em;
  border-radius: 1em;
  background: #18bc9c;
  color: white;
  position: relative;
}
.list-ic li::before {
  content: "";
  position: absolute;
  background: #18bc9c;
  z-index: -1;
}
.list-ic-dividend a {
  color: white;
  text-decoration: none;
}
.list-ic-dividend li {
  position: relative;
}
.list-ic-dividend li span {
  display: inline-block;
  font-weight: 800;
  width: 2em;
  height: 2em;
  text-align: center;
  line-height: 2em;
  border-radius: 1em;
  background: #18bc9c;
  color: white;
  position: relative;
}
.list-ic-dividend li::before {
  content: "";
  position: absolute;
  background: #18bc9c;
  z-index: 1;
}
.list-ic.horizontal li {
  display: inline-block;
}
.list-ic.horizontal li span {
  margin: 0 1em;
}
.list-ic.horizontal li::before {
  top: 0.9em;
  left: -25px;
  width: 4em;
  height: 0.2em;
}
.list-ic.vertical {
  padding: 0;
  margin: 0;
}
.list-ic.vertical li {
  list-style-type: none;
  text-align: left;
}
.list-ic.vertical li span {
  margin: 1.4em 0;
}
.list-ic.vertical li::before {
  top: -35px;
  left: 13px;
  width: 0.2em;
  height: 4em;
}
.list-ic li:first-child::before {
  display: none;
}
.list-ic .active {
  background: dodgerblue;
}
.list-ic .active ~ li {
  background: lightblue;
}
.list-ic .active ~ li::before {
  background: lightblue;
}
.list-ic-dividend.vertical {
  padding: 0;
  margin: 0;
}
.list-ic-dividend.vertical li {
  list-style-type: none;
  text-align: left;
}
.list-ic-dividend.vertical li span {
  margin: 1.4em;
}
.list-ic-dividend.vertical li::before {
  top: -34px;
  left: 15px;
  width: 0.2em;
  height: 1.5em;
  margin: 1.4em;
}
.list-ic-dividend li:first-child::before {
  display: none;
}
.list-ic-dividend .active {
  background: dodgerblue;
}
.list-ic-dividend .active ~ li {
  background: lightblue;
}
.list-ic-dividend .active ~ li::before {
  background: lightblue;
}
.pairblank {
  max-width: 1060px;
}
.r-list {
  --uirListPaddingLeft: var(--rListPaddingLeft, 0);
  --uirListMarginTop: var(--rListMarginTop, 0);
  --uirListMarginBottom: var(--rListMarginBottom, 0);
  --uirListListStyle: var(--rListListStyle, none);
  padding-left: var(--uirListPaddingLeft) !important;
  margin-top: 1.5rem !important;
  margin-bottom: var(--uirListMarginBottom) !important;
  list-style: var(--uirListListStyle) !important;
}
.r-link {
  --uirLinkDisplay: var(--rLinkDisplay, inline-flex);
  --uirLinkTextColor: #212529;
  --uirLinkTextDecoration: var(--rLinkTextDecoration, none);
  display: var(--uirLinkDisplay) !important;
  color: var(--uirLinkTextColor) !important;
  text-decoration: var(--uirLinkTextDecoration) !important;
}
.breadcrumb {
  --uiBreadcrumbDividerColor: var(--breadcrumbDividerColor, inherit);
  --uiBreadcrumbDividerSize: var(--breadcrumbDividerSize, 1rem);
  --uiBreadcrumbIndent: var(--breadcrumbIndent, 0.75rem);

  --uiBreadcrumbTextColor: var(--breadcrumbTextColor);
  --uiBreadcrumbTextColorActive: var(--breadcrumbTextColorActive);

  --breadcrumbDividerColor: var(--uiBreadcrumbTextColor);
  --rLinkTextColor: var(--uiBreadcrumbTextColor);
}
.breadcrumb__list {
  display: flex;
  flex-wrap: wrap;
  gap: var(--uiBreadcrumbIndent);
}
.breadcrumb__group {
  display: inline-flex;
  align-items: center;
}

span.breadcrumb__point {
  color: var(--uiBreadcrumbTextColorActive);
}
:root {
  --breadcrumbTextColor: #212529;
  --breadcrumbTextColorActive: #212529;
}
.breadcrumb_type5 {
  --breadcrumbDividerSize: 20px;
}

.navbar-col {
  max-width: 650px;
}
.search-col {
  min-width: 25%;
}
.search-col .form-control {
  background-color: #2d2f35;
  border-color: #44464c;
  width: 100%;
  padding-right: 45px;
  color: #f8f8f8;
}
.search-col .input-group-text {
  color: #bababa;
  position: absolute;
  right: 5px;
  background-color: transparent;
  border-left: solid 1px #44464c !important;
  z-index: 1000 !important;
}
.search-col .form-control::placeholder {
  color: rgba(255, 255, 255, 0.55);
}
.page {
  box-sizing: border-box;
  max-width: 600px;
  padding: 3rem 1rem;
  margin-left: auto;
  margin-right: auto;
}

.page__section:nth-of-type(n + 2) {
  margin-top: 2.5rem;
}
.backtop {
  background-color: #212529;
}

.list-mr a {
  text-decoration: none;
  color: black;
  cursor: pointer;
}
.list-mr a:hover {
  color: rgb(17, 74, 148);
}
.footer-global a {
  text-decoration: none;
  color: #9b9b9b;
  cursor: pointer;
}
.footer-global a:hover {
  color: rgb(17, 74, 148);
}
h5,
h5:hover {
  text-decoration: none;
  color: #9b9b9b;
  cursor: pointer;
}
.modal {
  padding-right: 0px !important;
}
td.day {
  height: 17px;
  font-weight: 700;
  padding-top: 2px;
}
.day {
  cursor: default;
  color: #3f4a54;
  border-top: 1px #bababa;
  line-height: 19px;
  text-align: center;
}
.sectionCenterTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.sectionTitle {
  font-size: 28px;
  line-height: 30px;
  font-weight: 700;
  color: rgb(59, 59, 59);
}
.market-pricing {
  border-radius: 2px;
  background-color: #f9f9f9;
}
.market-pricing .market-list {
  color: #9b9b9b;
  font-size: 14px;
  font-weight: 400;
  line-height: 48px;
  text-transform: uppercase;
  border-bottom: 2px solid #ededed;
  display: block;
}
.market-list span:first-child {
  padding-left: 30px;
  color: #282828;
  font-weight: 900;
  width: 40%;
}
.market-list span {
  display: inline-block;
}

.market-list .row {
  margin-right: 0px;
}
.text-link {
  display: inline !important;
  color: #0d6efd !important;
  text-decoration: none;
  transition: color 0.15s;
}
a,
a:hover,
a:focus,
a:active {
  color: inherit;
  text-decoration: none;
}
.aspect-ratio-container {
  position: relative;
  height: 0;
  padding-top: calc(4 / 4 * 100%);
}
.aspect-ratio-container-sd {
  position: relative;
  height: 0;
  padding-top: calc(50 / 200 * 100%);
}
.aspect-ratio-item {
  position: absolute;
  left: 0;
  top: 0;
  width: 100% !important;
  height: 100% !important;
}
.draft-ipo-list {
  position: relative;
  min-height: 200px;
}
.draft-ipo-list-content {
  position: absolute;
  padding: 15px;
  z-index: 3;
}
.draft-ipo-code-name {
  font-size: 16px;
  color: rgb(218, 215, 196);
  font-weight: bold;
}
.overlay {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.24);
  z-index: 1;
}
.public-offering-title-color {
  color: rgb(214, 144, 39);
}
.public-offering-title-color:hover {
  color: rgb(214, 144, 39);
}
.public-offering-header {
  border-style: inset;
  border-bottom-color: rgb(204, 145, 34);
  border-right-color: rgb(211, 174, 12);
  border-width: 10px;
  background-color: rgba(250, 247, 235, 0.493);
  padding-left: 5px;
  font-size: 40px;
  margin-bottom: 10px;
}
.footer-title {
  color: honeydew;
  text-align: left;
  font-size: 20px;
}
.dividend-title {
  text-align: center;
  background: rgb(199, 131, 43);
  cursor: auto;
  z-index: 100;
  box-shadow: 2px 2px 6px rgb(53, 53, 53);
}
.dividend-img {
  border: 2px solid rgb(255, 255, 255);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  overflow: hidden;
}
.company-news-img {
  border: 2px solid rgb(255, 255, 255);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  overflow: hidden;
  display: block;
  margin: auto;
  width: -webkit-fill-available;
}

.company-new-title {
  font-size: 22px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  min-height: 10px;
  position: relative;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: rgb(230, 230, 230);
  text-shadow: 1px 1px 1px rgb(0 0 0 / 80%);
  background-color: rgb(71, 71, 71);
  border-radius: 10px;
  box-shadow: inset 0 3px 0 1px rgb(0 0 0 / 30%);
}
.news-title {
  position: relative;
  font-size: 20px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: rgb(201, 201, 201);
  text-shadow: 1px 1px 1px rgb(0 0 0 / 80%);
  background-color: rgb(71, 71, 71);
  border-radius: 10px;
  box-shadow: inset 0 3px 0 1px rgb(0 0 0 / 30%);
}
.news-other-title {
  color: rgb(71, 71, 71);
  text-shadow: 1px 1px 1px rgb(0 0 0 / 80%);
}
.news-detail-title {
  color: rgb(255, 255, 255);
  text-shadow: 1px 1px 1px rgb(0 0 0 / 80%);
  background: content-box
    radial-gradient(rgba(226, 164, 29, 0.623), rgb(247, 100, 31));
  box-shadow: inset 0 3px 0 1px rgb(0 0 0 / 30%);
  border-radius: 0px 10px 10px 0px;
  text-overflow: ellipsis;
}
.news-img {
  border: 2px solid rgb(255, 255, 255);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  overflow: hidden;
}
.info-title {
  border-style: solid;
  border-block-start-color: #d7a237; /* Daha iyi kontrast için ayarlandı */
  border-block-end-color: #d7a237; /* Daha iyi kontrast için ayarlandı */
  border-radius: 10px;
  background-color: #faf2e4; /* Daha iyi kontrast için daha açık arka plan */
  color: #000000; /* Daha iyi kontrast için daha koyu metin */
  text-shadow: none; /* Daha iyi kontrast için kaldırıldı */
  text-align: center;
  font-size: 25px;
  font-display: swap;
  padding: 10px; /* Metin etrafında daha fazla yer için */
}
.info-img {
  border: 2px solid rgb(255, 255, 255);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  overflow: hidden;
  display: block;
  margin: auto;
  width: 400px;
}
.info-imgsd {
  border: 2px solid rgb(255, 255, 255);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  overflow: hidden;
  display: block;
  margin: auto;
  width: 100%;
}
.info-detail-title {
  font-size: 22px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
  text-align: center;
  font-weight: bold;
  color: white;
  padding: 12px;
  min-height: 40px;
  border-radius: 0px 0px 10px 10px;
  background: linear-gradient(to bottom, #d1ae4f 0%, #ee4040);
}
@media (max-width: 760px) {
  .info-detail-title {
    font-size: 20px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
    text-align: center;
    font-weight: bold;
    color: white;
    padding: 10px;
    min-height: 20px;
    border-radius: 0px 0px 10px 10px;
    background: linear-gradient(
      to bottom,
      #d1ae4f 0%,
      #ee4040
    ); /* Modern tarayıcılar için standart */
  }
}

.stockFalling-change {
  font-size: 18px;
}

@media (max-width: 760px) {
  .tables {
    font-size: 13px;
    overflow-x: auto;
  }
}

@media (max-width: 760px) {
  .button {
    width: 15px;
    height: 10px;
  }
}
@media only screen and (max-width: 760px) {
  h4.company-new-title {
    position: relative;
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: rgb(230, 230, 230);
    text-shadow: 1px 1px 1px rgb(0 0 0 / 80%);
    background-color: rgb(71, 71, 71);
    border-radius: 10px;
    box-shadow: inset 0 3px 0 1px rgb(0 0 0 / 30%);
  }
}
@media only screen and (max-width: 760px) {
  .info-img {
    border: 2px solid rgb(255, 255, 255);
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    overflow: hidden;
    display: block;
    margin: auto;
    width: 300px;
    height: 100px;
  }
}
@media (max-width: 760px) {
  .public-offering-header {
    border-style: inset;
    border-bottom-color: rgb(204, 145, 34);
    border-right-color: rgb(211, 174, 12);
    border-width: 10px;
    background-color: rgba(250, 247, 235, 0.493);
    padding-left: 5px;
    font-size: 20px;
  }
}

.background-none {
  background-color: Transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  display: inline-block;
}

.comments-button {
  color: black;
}
.comment-text {
  background-color: #424242;
  color: #ffffff;
}
.comment-text-area {
  background: #ebebeb;
  height: 100px;
  width: 100%;
  padding: 10px 25px;
  border: 1px solid #fff;
  border-radius: 8px;
}
.comments-div {
  display: block;
  float: left;
  font-size: 12px;
  color: #a1a1a1;
  width: auto;
  line-height: 20px;
}
.comments-list {
  display: block;
  float: left;
  width: 100%;
}
.comments-list-item {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  background: #ebebeb;
  border-radius: 8px;
}
.footer-mr {
  background: #212529;
  border: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.href {
  color: blue;
  cursor: pointer;
  font-size: 16px;
}
.href-wrap {
  min-width: 24px;
  min-height: 24px;
  padding-top: 8px;
}
.href2 {
  color: blue;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  text-decoration: underline;
}
.nav-links {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.nav-links li {
  margin-right: 10px;
}

.active-link h2 {
  color: #007bff;
  font-weight: bold;
}

.nav-links a:hover {
  text-decoration: underline;
}
.page-number {
  white-space: nowrap;
  display: inline-block;
}
.page-header {
  text-align: center;
}
.header-nav {
  color: #0050ad !important;
  background-color: #ffffff;
  font-size: 16px;
}
.contact-link {
  color: #9b9b9b;
}
.contact-link:hover {
  color: rgb(17, 74, 148);
}
.imgh img {
  width: 100%;
  height: auto;
}
